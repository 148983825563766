import React from 'react'
import _ from 'lodash'
import { formatToCEP } from 'brazilian-values'

import { Grid } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import DataTable from 'ui/components/ContainerCommonPanels/DataTable'
import { TitleBold } from 'ui/components/ContainerCommonPanels/styledComponents'
import { renderCheckService } from 'ui/components/ContainerCommonPanels/utilsFunctions'

import serviceStatusConstants from 'utils/constants/serviceStatus'
import { formatToCPF, formatToCNPJ } from 'utils/masks'

import {
  columnsHomonyms,
  columnsJobsHistory,
  columnsPartners,
  columnsRais,
  columnsRelatedPeople,
  columnsRelatives,
  columnsSocial,
  columnsUnemploymentInsuranceInfos,
} from './configDataTable'

import styles from './styles'

const ExpansionPanelCadastralExtension = ({ serviceInfos, personService }) => {
  const classes = styles()
  const theme = useTheme()

  const person = _.get(personService, 'data.person') || {}
  const personRelatedPeople = _.find(serviceInfos, { service_id: 'person_related_people' })

  const renderElectoralSituation = (service) => {
    const elemCheck = renderCheckService(service, theme)
    if (elemCheck !== null) return elemCheck

    if (!_.get(service, 'data.consists')) {
      return <strong style={{ color: theme.palette.primary.green }}>Sem Pendências</strong>
    }

    return <strong style={{ color: theme.palette.primary.red }}>Com Pendências</strong>
  }

  const renderSocialAssistance = () => {
    const service = _.find(serviceInfos, { service_id: 'social_assistance_person' })

    const elemCheck = renderCheckService(service, theme)
    if (elemCheck !== null) return elemCheck

    const data = _.get(service, 'data')

    const dataSocial = _.get(data, 'social_assistances')
    if (_.isEmpty(dataSocial)) return 'Não existem informações de Benefícios Sociais.'
    if (!_.isArray(dataSocial)) return ''

    return (
      <DataTable data={dataSocial} columns={columnsSocial} />
    )
  }

  const renderPep = (service, isRelatedPerson = false) => {
    if (isRelatedPerson !== true) {
      const elemCheck = renderCheckService(service, theme)
      if (elemCheck !== null) return elemCheck
    }

    const data = isRelatedPerson !== true ? _.get(service, 'data') : service

    if (!_.get(data, 'consists')) {
      return <strong style={{ color: theme.palette.primary.green }}>Não</strong>
    }

    const {
      jobs,
      office,
      partners,
      pep_level: pepLevel,
      pep_partners: pepPartners,
      pep_reason: pepReason,
      pep_relateds: pepRelateds,
      political_party_office: politicalPartyOffice,
    } = data

    const pepLevelText = {
      HIGH_RISK: 'Alto Risco',
      MEDIUM_RISK: 'Médio Risco',
      LOW_RISK: 'Baixo Risco',
      RELATED_PERSON: 'Pessoa Relacionada',
      NOT_PEP: 'Não é PEP',
    }[pepLevel] || pepLevel

    const pepReasonText = {
      NOT_PEP: 'Não é PEP',
      PEP_PARTNER: 'Sócio de PEP',
      PEP_RELATED: 'Pessoa relacionada PEP',
      POLITICAL_PARTY_LEADERSHIP: 'Possui cargo de liderança partidária',
      PUBLIC_SERVER: 'Servidor Público',
      RESTRICTIVE_LIST: 'Consta na lista da Transparência como PEP',
    }[pepReason] || pepReason


    return (
      <div className={classes.pepData}>
        <div className={classes.pepDataItem}>
          <strong style={{ color: theme.palette.primary.red, marginRight: 30 }}>Sim</strong>
          <span style={{ marginLeft: 30 }}>Nível:</span>
          <strong style={{ color: theme.palette.primary.red, marginLeft: 5 }}>{pepLevelText}</strong>
          <span style={{ marginLeft: 30 }}>Razão:</span>
          <strong style={{ color: theme.palette.primary.red, marginLeft: 5 }}>{pepReasonText}</strong>
        </div>

        {!_.isEmpty(office) && (
          <div className={classes.pepDataItem}>
            <span>Cargo Político:</span>
            <span style={{ marginLeft: 5 }}>{office}</span>
          </div>
        )}
        {!_.isEmpty(politicalPartyOffice) && (
          <div className={classes.pepDataItem}>
            <span>Cargo de liderança politica:</span>
            <span style={{ marginLeft: 5 }}>{politicalPartyOffice}</span>
          </div>
        )}

        {!_.isEmpty(jobs) && ['PUBLIC_SERVER'].includes(pepReason) && (
          <div className={classes.pepDataItem}>
            <span>Trabalhos:</span>
            <div style={{ marginLeft: 5 }}>
              {_.map(jobs, (job, i) => (
                <div key={i.toString()}>
                  <span>{_.chain(job).get('name').toLower().startCase().value()}</span>
                  <span>{' - '}</span>
                  <span>{formatToCNPJ(job.cnpj)}</span>
                </div>
              ))}
            </div>
          </div>
        )}

        {!_.isEmpty(partners) && ['PEP_PARNER'].includes(pepReason) && (
          <div className={classes.pepDataItem}>
            <span>Sócios:</span>
            <div style={{ marginLeft: 5 }}>
              {_.map(partners, (partner, i) => (
                <div key={i.toString()}>
                  <span>{_.chain(partner).get('name').toLower().startCase().value()}</span>
                  <span>{' - '}</span>
                  <span>{formatToCNPJ(partner.cnpj)}</span>
                </div>
              ))}
            </div>
          </div>
        )}

        {!_.isEmpty(pepPartners) && (
          <div className={classes.pepDataItem}>
            <span>Parentes PEP:</span>
            <div style={{ marginLeft: 5, marginTop: 15 }}>
              {_.map(pepPartners, (pepPartner, i) => (
                <div key={i.toString()} style={{ marginTop: 5 }}>
                  <span>{_.chain(pepPartner).get('name').toLower().startCase().value()}</span>
                  <span>{' - '}</span>
                  <span>{formatToCPF(pepPartner.cpf)}</span>
                  {renderPep(pepPartner, true)}
                  <hr />
                </div>
              ))}
            </div>
          </div>
        )}

        {!_.isEmpty(pepRelateds) && (
          <div className={classes.pepDataItem}>
            <span>Relacionados PEP:</span>
            <div style={{ marginLeft: 5, marginTop: 15 }}>
              {_.map(pepRelateds, (pepRelated, i) => (
                <div key={i.toString()} style={{ marginTop: 5 }}>
                  <span>{_.chain(pepRelated).get('name').toLower().startCase().value()}</span>
                  <span>{' - '}</span>
                  <span>{formatToCPF(pepRelated.cpf)}</span>
                  {renderPep(pepRelated, true)}
                  <hr />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    )
  }

  const renderService = (service) => {
    const elemCheck = renderCheckService(service, theme)
    if (elemCheck !== null) return elemCheck

    if (!_.get(service, 'data.consists')) {
      return <strong style={{ color: theme.palette.primary.green }}>Não</strong>
    }

    return <strong style={{ color: theme.palette.primary.red }}>Sim</strong>
  }

  const renderPersonRelatives = () => {
    let relatives = _.get(person, 'relatives')
    if (_.isEmpty(relatives)) return null

    relatives = _
    .chain(relatives)
    .filter((item) => !_.isEmpty(item.name))
    .map(item => ({ name: item.name, cpf: item.cpf, birth_date: item.birth_date }))
    .value()

    if (_.isEmpty(relatives)) return null

    return (
      <div style={{ width: '100%' }}>
        <TitleBold style={{ marginTop: 20 }}>Possiveis Parentes</TitleBold>
        <DataTable
          data={relatives}
          columns={columnsRelatives}
        />
      </div>
    )
  }

  const renderRelatedPeople = () => {
    const service = personRelatedPeople

    const elemCheck = renderCheckService(service, theme)

    const title = <TitleBold style={{ marginTop: 15, marginBottom: 15 }}>Relações familiares</TitleBold>

    if (elemCheck !== null) {
      return (
        <div>
          {title}
          {elemCheck}
        </div>
      )
    }

    const data = _.get(service, 'data.related_people')

    return (
      <div>
        {title}
        <DataTable
          data={data}
          columns={columnsRelatedPeople}
        />
      </div>
    )
  }

  const renderRelatives = () => {
    if (!_.isEmpty(personRelatedPeople.data_id)) return renderRelatedPeople()

    return renderPersonRelatives()
  }

  const renderHomonyms = () => {
    const service = _.find(serviceInfos, { service_id: 'person_infos_by_name' })

    const elemCheck = renderCheckService(service, theme)
    if (elemCheck !== null) return elemCheck

    let results = _.get(service, 'data.results')
    results = _.reject(results, (item) => item.cpf === person.cpf)

    if (_.isEmpty(results)) return 'Nenhum homônimo encontrado'
    if (!_.isArray(results)) return ''

    return (
      <div style={{ width: '100%' }}>
        <p>{`${results.length} homônimo(s) encontrado(s)`}</p>
        <DataTable
          data={results}
          columns={columnsHomonyms}
        />
      </div>
    )
  }

  const renderPartners = () => {
    const cnpjsInfos = _.filter(serviceInfos, { service_id: 'membership_board' })

    const cnpjsInfosData = _
    .chain(cnpjsInfos)
    .map(cnpjInfo => {
      const cnpjInfoData = _.get(cnpjInfo, 'data') || {}
      const zipcode = formatToCEP(_.get(cnpjInfoData, 'address.zipcode') || '')

      const newItem = {
        address: _.chain(cnpjInfoData).get('address').pick(['public_place', 'number', 'complement', 'neighborhood']).values().push(zipcode).without(null, undefined, '').join(', ').value(),
        city: _.chain(cnpjInfoData).get('address').pick(['city', 'uf']).values().without(null, undefined, '').join('/').value(),
        cnpj: _.chain(cnpjInfoData).get('cnpj').toLower().startCase().value(),
        name: _.chain(cnpjInfoData).get('name').toLower().startCase().value(),
        type: _.chain(cnpjInfoData).get('legal_nature.description').toLower().startCase().value(),
      }

      if (cnpjInfo.status === serviceStatusConstants.SERVICE_STATUS.processing) {
        newItem.cadastral_situation = cnpjInfo.response_time
        newItem.members = cnpjInfo.response_time
      } else if (cnpjInfo.status === serviceStatusConstants.SERVICE_STATUS.processed) {
        newItem.cadastral_situation = _.get(cnpjInfo, 'data.cadastral_situation')
        newItem.members = _.get(cnpjInfo, 'data.membership_board')
      }

      return newItem
    })
    .value()

    const elemChild = _.isEmpty(cnpjsInfosData)
      ? <div style={{ color: theme.palette.primary.grayLight2, marginTop: 15 }}>Não solicitado</div>
      : (
        <DataTable
          data={cnpjsInfosData}
          columns={columnsPartners}
        />
      )

    return (
      <div style={{ width: '100%' }}>
        <TitleBold style={{ marginTop: 20 }}>Sociedades</TitleBold>
        {elemChild}
      </div>
    )
  }

  const renderJobsHistory = () => {
    let jobsHistory = _.get(person, 'jobs') || _.get(person, 'jobs_history')
    if (_.isEmpty(jobsHistory)) return null

    jobsHistory = _
    .chain(jobsHistory)
    .filter((item) => !_.isEmpty(item.name))
    .value()

    if (_.isEmpty(jobsHistory)) return null

    return (
      <div style={{ width: '100%' }}>
        <TitleBold style={{ marginTop: 20 }}>Empregos</TitleBold>
        <DataTable
          data={jobsHistory}
          columns={columnsJobsHistory}
        />
      </div>
    )
  }

  const renderElectoralSituationContainer = (serviceId) => {
    const titleNumber = _.get(person, 'elector_title_number')
    return (
      <React.Fragment>
        {titleNumber && <strong style={{ marginRight: '20px' }}>{titleNumber}</strong>}
        {renderElectoralSituation(serviceId)}
      </React.Fragment>
    )
  }

  const renderListServices = () => {
    const serviceIds = [
      'pep_search_v2',
      'bacen_qgi_cpf',
      'sanctions_list',
      'eu_sanction_list',
      'uk_sanction_list',
      'fr_sanction_list',
      'us_nonproliferation_sanction_list',
      'red_notice_interpol',
      'onu_sanction_lists',
      'world_bank_ineligible_sanction_list',
      'electoralsituation_by_cpf',
    ]

    return _.map(serviceIds, (serviceId) => {
      const service = _.find(serviceInfos, { service_id: serviceId })
      if (_.isEmpty(service)) return null

      let functionRender = null
      switch (serviceId) {
        case 'pep_search_v2':
          functionRender = renderPep
          break
        case 'electoralsituation_by_cpf':
          functionRender = renderElectoralSituationContainer
          break
        default:
          functionRender = renderService
          break
      }

      return (
        <Grid key={serviceId} container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={3}>
            <div>{service.name_short}</div>
          </Grid>
          <Grid item xs={9} style={{ paddingLeft: 20 }}>
            {functionRender(service)}
          </Grid>
        </Grid>
      )
    })
  }

  const renderUnemploymentInsuranceInfos = () => {
    const service = _.find(serviceInfos, { service_id: 'unemployment_insurance_infos' })

    const elemCheck = renderCheckService(service, theme)
    if (elemCheck !== null) return elemCheck

    const data = _.get(service, 'data')

    const dataInsurances = _.get(data, 'historic_insurance')
    if (_.isEmpty(dataInsurances)) return 'Não existem informações'
    if (!_.isArray(dataInsurances)) return ''

    return (
      <React.Fragment>
        <h4 style={{ marginTop: 15 }}>Requerimentos</h4>
        <DataTable
          data={dataInsurances}
          columns={columnsUnemploymentInsuranceInfos}
        />
      </React.Fragment>
    )
  }

  const renderRais = () => {
    const service = _.find(serviceInfos, { service_id: 'work_info_rais_base' })

    const elemCheck = renderCheckService(service, theme)
    if (elemCheck !== null) return elemCheck

    const data = _.get(service, 'data')

    const dataCompaniesInfos = _.get(data, 'companies_infos')
    if (_.isEmpty(dataCompaniesInfos)) return 'Não existem informações no RAIS.'
    if (!_.isArray(dataCompaniesInfos)) return ''

    return (
      <React.Fragment>
        <h4 style={{ marginTop: 15 }}>Empresas</h4>
        <DataTable
          data={dataCompaniesInfos}
          columns={columnsRais}
        />
      </React.Fragment>
    )
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', fontSize: 16 }}>
      {renderListServices()}

      <div>
        <TitleBold style={{ marginTop: 15, marginBottom: 15 }}>Benefícios Sociais</TitleBold>
        {renderSocialAssistance()}
      </div>

      <div>
        <TitleBold style={{ marginTop: 15, marginBottom: 15 }}>Seguro Desemprego</TitleBold>
        {renderUnemploymentInsuranceInfos()}
      </div>

      <div>
        <TitleBold style={{ marginTop: 15, marginBottom: 15 }}>RAIS - Vinculos empregatícios</TitleBold>
        {renderRais()}
      </div>

      <div>
        <TitleBold style={{ marginTop: 15, marginBottom: 15 }}>Homônimos (pessoas com o mesmo nome)</TitleBold>
        {renderHomonyms()}
      </div>

      {renderRelatives()}
      {renderJobsHistory()}
      {renderPartners()}
    </div>
  )
}

export default ExpansionPanelCadastralExtension
